<template>
	<div>
		<template>
			<div style="width: 100%; display: flex; justify-content: space-around">
				<div
					style="
						width: 280px;
						margin-top: 8px;
						/* display: grid; */
						padding-right: 24px;
						margin-left: 20px;
						/* gap: 4px; */
						/* grid-template-columns: repeat(minmax(180px, 1fr)); */
						/* grid-template-rows: 270px 40px 300px; */
					">
					<div id="CharacterGrid" style="display: flex; flex-direction: column; position: fixed; top: 280px">
						<div class="character-slide-card-v3" @click="classifyItem">
							<div class="chartacter-item" style="width: 180px; height: 206px; padding: 0px 12px; cursor: pointer">
								<div style="display: flex; flex-direction: column">
									<div
										style="
											width: 108px;
											height: 108px;
											margin: auto;
											border-radius: 14px;
											aspect-ratio: 1 / 1;
											background-size: cover;
											margin-bottom: 8px;
											filter: saturate(1);
											font-size: 2em;
											justify-content: center;
											align-items: center;
											text-align: center;
											display: flex;
											position: relative;
										">
										<img
											width="100%"
											height="100%"
											style="border-radius: 14px"
											:src="form.image == '' || form.image == null ? avater : form.image"
											alt="" />
										<div
											v-show="reseller"
											style="
												width: 25px;
												height: 25px;
												position: absolute;
												top: 83px;
												right: 0;
												background-color: #1890ff;
												text-align: center;
												line-height: 20px;
												border-radius: 15px 0px 13px 0px;
											">
											<img
												style="width: 20px; height: 20px; margin-top: 5px; border-radius: 10px"
												src="../../assets/imgs/renzheng2.png"
												alt="" />
										</div>
									</div>
									<div
										style="
											font-size: 14px;
											font-weight: bold;
											margin-top: 15px;
											margin-bottom: 8px;
											overflow: hidden;
											width: 95%;
											text-overflow: ellipsis;
											display: block;
											text-align: center;
											-webkit-line-clamp: 1;
											white-space: nowrap;
										">
										{{ form.title != "" ? form.title : "wow" }}
										<!-- {{ form.title }} -->
										<img
											style="margin-bottom: 5px"
											v-if="form.sex == 2"
											width="15"
											height="15"
											src="../../assets/imgs/woman.png"
											alt="" />
										<img
											style="margin-bottom: 5px"
											v-else
											width="15"
											height="15"
											src="../../assets/imgs/man.png"
											alt="" />
									</div>
									<div
										style="
											font-size: 12px;
											text-align: left;
											max-width: 100%;
											margin-left: -6px;
											display: -webkit-box;
											-webkit-box-orient: vertical;
											-webkit-line-clamp: 3;
											overflow: hidden;
											text-overflow: ellipsis;
										">
										{{ form.welcome != "" ? form.welcome : "我是wow" }}
									</div>
								</div>
							</div>
							<div style="display: flex; justify-content: space-between; align-items: center; width: 105%">
								<div
									class="username-truncated"
									style="font-size: 12px; width: 85px; display: block; overflow: hidden; text-overflow: ellipsis">
									<div style="color: inherit; text-decoration: none; --darkreader-inline-color: inherit">
										@ {{ nickname }}
									</div>
								</div>
								<div style="display: flex; flex-direction: row; align-items: center">
									<div class="collect" style="font-size: 12px; cursor: pointer">
										<img width="12" height="12" src="../../assets/imgs/对话svg图标.svg" alt="" />
										{{ form.usages + form.fake_usages ? form.usages + form.fake_usages : 0 }}
									</div>
								</div>
							</div>
						</div>
						<div class="share" id="share">
							<div @click="link('weixin')" class="img1"></div>
							<div @click="link('qq')" class="img2"></div>
							<div @click="link('other')" class="img4"></div>
							<div @click="canvasCode" class="img3"></div>
						</div>
						<a-form-model-item label="角色隐私" :colon="false" :label-col="{ span: 8 }" :wrapper-col="{ span: 10 }">
							<a-radio-group v-model="form.is_publicity" style="display: flex; margin-top: 15px">
								<a-tooltip placement="topLeft" title="任何人都可以对话" arrow-point-at-center>
									<a-radio :value="1"> 公开 </a-radio>
								</a-tooltip>
								<a-tooltip placement="topLeft" title="只有您可以对话" arrow-point-at-center>
									<a-radio :value="2"> 私有 </a-radio>
								</a-tooltip>
							</a-radio-group>
							<a-button style="margin-left: 5px; margin-top: 10px;background: #dab2f9; border: 1px solid #dab2f9" type="primary" @click="submitForm">
								<span v-if="!getID">创建</span>
								<span v-else>保存</span>
							</a-button>
						</a-form-model-item>
					</div>
				</div>
				<!-- 表单 -->
				<a-form-model
					ref="ruleForm"
					:model="form"
					:rules="rules"
					:class="{ disable: type === true ? true : false }"
					style="width: calc(100% - 300px); margin-right: 25px">
					<!-- 折叠面板 -->
					<a-collapse v-model="activeKey" style="width: 100%" expand-icon-position="right">
						<a-collapse-panel key="1">
							<template #header>
								<img style="margin-top: -4px" width="15" height="15" src="../../assets/imgs/renshe.png" alt="" />
								人设设置
								<span class="time">
									<span>{{ form.updatetime == undefined ? moment().format("YYYY-MM-DD") : form.updatetime }} </span>
								</span>
							</template>
							<div>
								<div class="default_role">
									<div v-for="(item, index) in defaultRole" :key="index">
										<a-tooltip placement="bottomRight">
											<template slot="title">
												<span>{{ item.title }}</span>
											</template>
											<img
												:src="item.image"
												width="50"
												height="50"
												style="border-radius: 50%"
												alt=""
												@click="changeRole(item)" />
										</a-tooltip>
									</div>
								</div>
								<div style="position: relative; pointer-events: none">
									<a-form-model-item
										prop="image"
										:colon="false"
										:label-col="{ span: 3 }"
										:wrapper-col="{ span: 9 }"
										style="position: relative">
										<template slot="label">
											<div style="margin-right: 15px">
												<router-link to="/drawing/picture?id=2" target="_blank">
													<a-tooltip placement="topLeft" title="点此去生成网红头像" arrow-point-at-center>
														<a-icon class="avater-icon" type="exclamation-circle" />
													</a-tooltip>
												</router-link>
												头像
											</div>
										</template>
										<div class="upload">
											<div class="image" v-if="picture.image">
												<img :src="picture.image" alt="" :style="{ pointerEvents: type === true ? 'none' : 'auto' }" />
												<div class="delete" :style="{ pointerEvents: type === true ? 'none' : 'auto' }">
													<a-icon type="delete" :style="{ fontSize: '20px' }" @click="deleteUploadPicture" />
												</div>
											</div>
											<div
												class="add"
												v-else
												@click="isToken('picture')"
												:style="{ pointerEvents: type === true ? 'none' : 'auto' }">
												<a-icon type="plus" :style="{ fontSize: '26px', color: '#4c5b74' }" />
											</div>
										</div>
									</a-form-model-item>
								</div>
								<a-form-model-item
									label="角色性别"
									prop="sex"
									:colon="false"
									:label-col="{ span: 3 }"
									:wrapper-col="{ span: 9 }">
									<a-radio-group v-model="form.sex">
										<a-radio :value="1"> 男 </a-radio>
										<a-radio :value="2" style="position: relative; top: -1px; left: 7px"> 女 </a-radio>
									</a-radio-group>
								</a-form-model-item>
								<a-form-model-item label="角色分类" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 15 }">
									<a-select v-model="form.topic_id" placeholder="请选择分类" @select="handlerSelect">
										<a-select-option v-for="item in topic" :key="item.id" :value="item.id">
											{{ item.title }}
										</a-select-option>
									</a-select>
								</a-form-model-item>
								<a-form-model-item
									prop="title"
									label="角色名字"
									:colon="false"
									:label-col="{ span: 3 }"
									:wrapper-col="{ span: 3 }">
									<a-input
										style="width: 160px"
										v-model="form.title"
										placeholder="请输入名字"
										:maxLength="8"
										@change="changeRoleModule(form.title, 'title')"
										@focus="isToken('title')">
										<span slot="suffix"> {{ form.title.length }}/8</span>
									</a-input>
								</a-form-model-item>
								<a-form-model-item
									prop="birthday"
									label="出生日期"
									:colon="false"
									:label-col="{ span: 3 }"
									:wrapper-col="{ span: 15 }">
									<a-date-picker @change="changeTime" v-model="form.birthday" />
									<span style="color: #999"> 该角色今年{{ 2023 - age }} 岁</span>
								</a-form-model-item>
								<a-form-model-item label="欢迎语" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 15 }">
									<a-input
										v-model="form.welcome"
										placeholder="与用户初次交流时，Ta会用此段文字自我介绍"
										:maxLength="180"
										@change="changeRoleModule(form.welcome, 'welcome')"
										@focus="isToken('welcome')">
										<!-- <span slot="suffix"> {{ form.welcome.length }}/18</span> -->
									</a-input>
								</a-form-model-item>
								<a-form-model-item
									prop="title"
									label="角色性格"
									:colon="false"
									:label-col="{ span: 3 }"
									:wrapper-col="{ span: 21 }">
									<a-radio-group
										v-model="radioData"
										size="small"
										@change="changeRadio"
										button-style="solid"
										id="radio"
										style="position: relative">
										<a-radio-button
											:value="index + 1"
											v-for="(item, index) in characterRadio"
											:key="index"
											:class="color[Number(index)]"
											>{{ item }}
											<div style="position: absolute; right: 0px; top: -10px" v-show="radioData == index + 1">
												<img src="../../assets/imgs/gou.png" width="12" height="12" alt="" />
											</div>
										</a-radio-button>
									</a-radio-group>
									<div class="character" :style="{ 'background-image': 'url(' + backIndex + ') ' }">
										<div class="name" id="name">{{ name }}</div>
										<a-tag color="#dab2f9" v-for="(item, index) in tagList" :key="index">{{ item }}</a-tag>
										<div id="name">{{ checkedText }}</div>
									</div>
								</a-form-model-item>
							</div>
							<div></div>
						</a-collapse-panel>
						<a-collapse-panel key="2" :disabled="false">
							<template #header>
								<img style="margin-top: -4px" width="15" height="15" src="../../assets/imgs/shengxian.png" alt="" />
								声线设置
								<span class="time">
									<span>{{ form.vrs_type == 1 ? "虚拟声音" : "克隆原声" }} </span>
								</span>
							</template>
							<div style="display: flex; justify-content: space-around; box-sizing: border-box">
								<!-- display: grid; grid-template-columns: 240px 240px 240px; grid-template-rows: 100px; -->
								<!-- display: flex; justify-content: space-around; align-items: space-around; flex-wrap: wrap -->
								<div
									style="
										display: grid;
										grid-template-columns: 240px 240px 240px;
										grid-template-rows: 100px 100px 100px 100px;
									">
									<!-- :class="{ type: index == 0 }" -->
									<div
										v-for="(item, index) in soundList"
										:key="item.voice_id"
										class="sound"
										id="sound"
										:class="{ type: changeVoiceId == item.voice_id }">
										<div
											style="display: flex; align-items: center; margin: auto 10px; position: relative"
											@click="changeSound(item, index)">
											<a-avatar :size="60" icon="item.img" :src="item.img" />
											<div style="color: #000; font-size: 12px; margin-left: 10px">
												<div>
													<span style="font-size: 14px"> {{ item.name }}</span>
													<span style="color: #999; font-size: 10px; padding: 2px">{{ item.num }}种风格</span>
												</div>
												<a-tag
													v-for="(i, j) in item.scene"
													:key="j"
													color="#dab2f9"
													style="color: #fff; margin-bottom: 5px; font-size: 10px; padding: 2px">
													{{ i }}
												</a-tag>
											</div>
											<div style="position: absolute; right: 5px; top: 15px; cursor: pointer">
												<!-- <a-icon type="play-circle" style="font-size: 25px" @click="playVoice(item)" /> -->
												<div class="playImg" @click="playVoice(item)"></div>
												<!-- <img
													:src="changeVoiceId == item.voice_id ? bluePlay : defaultPlay"
													width="25"
													height="25"
													alt=""
													@click="playVoice(item)" /> -->
											</div>
										</div>
										<div style="position: absolute; top: 0; right: 0" v-show="changeVoiceId == item.voice_id">
											<img src="../../assets/imgs/complete.png" width="30" height="30" alt="" />
										</div>
									</div>
								</div>
								<div
									v-for="(item, index) in feelingList"
									:key="index"
									style="display: grid; grid-template-columns: 500px; grid-template-rows:  50px 50px 50px 50px">
									<a-input :placeholder="placeholder" v-model="text" :maxLength="20" @focus="isToken('text')">
										<span slot="suffix"> {{ text.length }}/20</span>
									</a-input>
									<div class="style" style="display: flex; line-height: 47px">
										<p style="margin-right: 20px">风格</p>
										<a-button
											size="small"
											style="width: 45px; display: flex; justify-content: center; align-items: center; margin-top: 8px"
											v-for="(val, key, j) in item.emotion_category"
											:key="j"
											:class="{ atnfoucs: defaultStyle == key }"
											@click="selectStyle(val, key)">
											{{ val }}
										</a-button>
									</div>
									<div style="display: flex; line-height: 39px">
										<p>情感</p>
										<c-progress
											class="c-progress"
											:percent="emotion_intensity"
											style="margin-left: 20px; cursor: pointer; margin-top: -10px"
											@percentChange="onPercentChange" />
									</div>
									<div style="display: flex; line-height: 25px">
										<p>语速</p>
										<c-progress
											class="c-progress"
											:percent="70"
											style="margin-left: 20px; cursor: pointer; margin-top: -23px"
											@percentChange="onPercentChangeSpeed" />
									</div>
									<div style="display: flex; line-height: 20px">
										<p>音量</p>
										<c-progress
											class="c-progress"
											:percent="70"
											style="margin-left: 20px; cursor: pointer; margin-top: -75px"
											@percentChange="onPercentChangeVolume" />
									</div>
									<div>
										<a-button
											type="primary"
											size="small"
											@click="saveSound('sound')"
											style="margin-right: 10px; margin-left: 5px;background: #dab2f9; border: 1px solid #dab2f9"
											>试听
											<audio ref="audio" @ended="isPlay = false" :src="audioSrc"></audio>
										</a-button>
									</div>
								</div>
							</div>
						</a-collapse-panel>
						<!-- <a-collapse-panel key="3" :disabled="false">
                            <template #header> <img style="margin-top: -4px;" width="15" height="15"
                                    src="../../assets/imgs/waimao.png" alt=""> 外貌设置（角色尚未认证）
                            </template>
                            <a-form-model-item prop="title" label="网红外貌" :colon="false" :label-col="{ span: 3 }"
                                :wrapper-col="{ span: 9 }">
                                <a-date-picker>
                                    <template slot="dateRender" slot-scope="current, today">
                                        <div class="ant-calendar-date" :style="getCurrentStyle(current, today)">
                                            {{ current.date() }}
                                        </div>
                                    </template>
                                </a-date-picker>
                            </a-form-model-item>
                        </a-collapse-panel> -->
						<!-- :disabled="false" -->
						<a-collapse-panel key="4">
							<template #header>
								<img style="margin-top: -4px" width="15" height="15" src="../../assets/imgs/jiyi.png" alt="" />
								记忆库<span class="time">
									<span> {{ memory_length ? memory_length : 4 }}篇</span>
								</span>
							</template>
							<a-form-model-item label="角色宠物" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
								<a-input
									v-model="form.pet"
									type="textarea"
									row="3"
									:maxLength="300"
									placeholder="示例：小明很喜欢猫，他自己养了两只猫，一直是布偶叫元宝，一直是英短叫金角…"
									@focus="isToken()">
								</a-input>
							</a-form-model-item>
							<a-form-model-item label="成长经历" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
								<a-input
									v-model="form.grow"
									type="textarea"
									row="3"
									:maxLength="300"
									@focus="isToken()"
									placeholder="示例：小明从小在北京长大，高中毕业后继续在北京的大学读书，专业学的是计算机…">
								</a-input>
							</a-form-model-item>
							<a-form-model-item label="兴趣爱好" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
								<a-input
									v-model="form.interest"
									type="textarea"
									row="3"
									:maxLength="300"
									@focus="isToken()"
									placeholder="示例：小明闲暇时间喜欢打游戏，最喜欢的游戏是王者荣耀，因为可以跟朋友一起组队…">
								</a-input>
							</a-form-model-item>
							<a-form-model-item label="专业知识" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
								<a-input
									v-model="form.knowledge"
									type="textarea"
									row="3"
									:maxLength="300"
									@focus="isToken()"
									placeholder="示例：小明很擅长摄影，知道如何运用光影拍摄出效果更好的照片，所以经常指导身边的朋友们…">
								</a-input>
							</a-form-model-item>
							<a-form-model-item
								:colon="false"
								v-for="(domain, index) in dynamicValidateForm.domains"
								:key="domain.key"
								v-bind="formItemLayout"
								:label="domain.value"
								:label-col="{ span: 3 }"
								:wrapper-col="{ span: 18 }">
								<a-input
									v-model="domain.label"
									type="textarea"
									row="3"
									:maxLength="300"
									placeholder="please input domain" />
								<a-icon
									v-if="dynamicValidateForm.domains.length > 0"
									class="dynamic-delete-button"
									type="minus-circle-o"
									style="position: absolute"
									:disabled="dynamicValidateForm.domains.length === 0"
									@click="removeDomain(domain)" />
							</a-form-model-item>

							<a-form-model-item
								v-bind="formItemLayoutWithOutLabel"
								:label-col="{ span: 3 }"
								:colon="false"
								:wrapper-col="{ span: 18 }">
								<template slot="label">
									<div style="width: 50px"></div>
								</template>

								<a-button type="dashed" style="width: 200px" @click="addDomain">
									<a-icon type="plus" /> <span style="display: inline-block; margin-left: 5px"> 添加记忆</span>
								</a-button>
							</a-form-model-item>
						</a-collapse-panel>
						<!-- <a-collapse-panel key="5" :disabled="false">
                            <template #header> <img style="margin-top: -4px;" width="15" height="15"
                                    src="../../assets/imgs/xingge.png" alt=""> 性格训练（角色尚未认证）
                            </template>
                            <a-form-model-item prop="title" label="性格训练" :colon="false" :label-col="{ span: 3 }"
                                :wrapper-col="{ span: 9 }">
                                <a-date-picker>
                                    <template slot="dateRender" slot-scope="current, today">
                                        <div class="ant-calendar-date" :style="getCurrentStyle(current, today)">
                                            {{ current.date() }}
                                        </div>
                                    </template>
                                </a-date-picker>
                            </a-form-model-item>
                        </a-collapse-panel> -->
						<!-- <a-collapse-panel key="6" :disabled="false">
                            <template #header> <img style="margin-top: -4px;" width="15" height="15"
                                    src="../../assets/imgs/fensihuaxiang.png" alt="">
                                粉丝画像（角色尚未认证）
                            </template>
                            <a-form-model-item prop="title" label="粉丝画像" :colon="false" :label-col="{ span: 3 }"
                                :wrapper-col="{ span: 9 }">
                                <a-date-picker>
                                    <template slot="dateRender" slot-scope="current, today">
                                        <div class="ant-calendar-date" :style="getCurrentStyle(current, today)">
                                            {{ current.date() }}
                                        </div>
                                    </template>
                                </a-date-picker>
                            </a-form-model-item>
                        </a-collapse-panel> -->

						<!-- <a-collapse-panel key="7" :disabled="false">
							<template #header>
								<img style="margin-top: -4px" width="15" height="15" src="../../assets/imgs/fnexiang.png" alt="" />
								社交主页<span class="time">{{
									form.updatetime == undefined ? moment().format("YYYY-MM-DD") : form.updatetime
								}}</span>
							</template>
							<a-form-model-item label="微博主页" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 9 }">
								<a-input v-model="form.social_link[0].wb" placeholder="请输入微博链接" :maxLength="100"> </a-input>
							</a-form-model-item>
							<a-form-model-item
								prop="xhs"
								label="小红书主页"
								:colon="false"
								:label-col="{ span: 3 }"
								:wrapper-col="{ span: 9 }">
								<a-input v-model="form.social_link[1].xhs" placeholder="请输入小红书链接" :maxLength="100"> </a-input>
							</a-form-model-item>
							<a-form-model-item
								prop="dy"
								label="抖音主页"
								:colon="false"
								:label-col="{ span: 3 }"
								:wrapper-col="{ span: 9 }">
								<a-input v-model="form.social_link[2].dy" placeholder="请输入抖音链接" :maxLength="100"> </a-input>
							</a-form-model-item>
							<a-form-model-item
								prop="other"
								label="其他主页"
								:colon="false"
								:label-col="{ span: 3 }"
								:wrapper-col="{ span: 9 }">
								<a-input v-model="form.social_link[3].other" placeholder="请输入其他链接" :maxLength="100"> </a-input>
							</a-form-model-item>
							<a-form-model-item
								prop="social_code"
								label="角色主页默认显示"
								:colon="false"
								:label-col="{ span: 3 }"
								:wrapper-col="{ span: 9 }">
								<a-radio-group v-model="form.social_code">
									<a-radio value="wb"> 微博主页 </a-radio>
									<a-radio value="xhs"> 小红书主页 </a-radio>
									<a-radio value="dy"> 抖音主页 </a-radio>
									<a-radio value="other"> 其他主页 </a-radio>
								</a-radio-group>
							</a-form-model-item>
						</a-collapse-panel> -->
					</a-collapse>
				</a-form-model>
			</div>

			<!--记忆库弹窗  -->
			<a-modal
				title="添加记忆库"
				:visible="visibleMemory"
				okText="添加"
				cancelText="取消"
				:confirm-loading="confirmLoading"
				@ok="handleOkMemory"
				@cancel="handleCancelMemory">
				<a-form-model
					ref="ruleFormMemory"
					:model="formMemory"
					:rules="rulesMemory"
					:label-col="labelCol"
					:wrapper-col="wrapperCol">
					<a-form-model-item
						label="名称"
						:colon="false"
						prop="name"
						:label-col="{ span: 3 }"
						:wrapper-col="{ span: 18 }">
						<a-input v-model="formMemory.name" placeholder="请输入记忆库名" :maxLength="20"> </a-input>
					</a-form-model-item>
					<a-form-model-item
						label="内容"
						:colon="false"
						prop="text"
						:label-col="{ span: 3 }"
						:wrapper-col="{ span: 18 }">
						<a-input v-model="formMemory.text" placeholder="请输入记忆内容" type="textarea" row="3" :maxLength="300">
						</a-input>
					</a-form-model-item>
				</a-form-model>
			</a-modal>

			<!-- 上传图片 -->
			<a-modal
				v-model="picture.show"
				title="添加图片"
				width="70%"
				cancelText="取消"
				okText="确定"
				:maskClosable="false"
				@ok="addPictureOkHandle">
				<div class="picture_box">
					<div class="box_head">
						<a-radio-group v-model="picture.type" size="small">
							<a-radio-button :value="1"><span style="color: #000">我的作品</span></a-radio-button>
							<a-radio-button :value="2"><span style="color: #000">本地上传</span></a-radio-button>
						</a-radio-group>
					</div>
					<div class="box_body">
						<div class="image_list" v-if="picture.type == 1" @scroll="addPictureScroll">
							<a-row type="flex" align="middle">
								<a-col
									:span="24"
									:sm="12"
									:md="8"
									:lg="6"
									:xl="4"
									v-for="(item, index) in works.data"
									:key="'works_' + index">
									<div class="item">
										<div
											class="image"
											:class="{ active: picture.active == item.id }"
											@click="changeAddPicture(item.id, item.image)">
											<img :src="item.image" alt="" />
										</div>
									</div>
								</a-col>
							</a-row>
							<div class="status_text" v-if="works.data.length">
								<span class="more" v-if="works.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
								<span v-if="works.status == 'loading'">加载中...</span>
								<span v-if="works.status == 'nomore'">没有更多了</span>
							</div>
						</div>
						<div class="image_list" v-if="picture.type == 2">
							<a-row type="flex" align="middle">
								<a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4">
									<div class="item">
										<div class="upload">
											<!-- :file-list="fileList" -->
											<a-upload
												name="file"
												list-type="picture-card"
												:show-upload-list="false"
												:action="upload.action"
												:headers="upload.headers"
												:accept="upload.accept"
												:data="upload.data"
												:before-upload="uploadPictureBefore"
												@change="uploadPictureChange">
												<p>
													<a-icon
														class="plus_upload"
														:type="upload.loading ? 'loading' : 'plus'"
														:style="{ fontSize: '24px', color: '#8895a4' }" />
												</p>
												<div class="text" v-if="!upload.loading">
													支持JPG、PNG格式 <br />
													20M内
												</div>
											</a-upload>
										</div>
									</div>
								</a-col>
								<a-col
									:span="24"
									:sm="12"
									:md="8"
									:lg="6"
									:xl="4"
									v-for="(item, index) in local.data"
									:key="'local_' + index">
									<div class="item">
										<div
											class="image"
											:class="{ active: picture.active == item.id }"
											@click="changeAddPicture(item.id, item.image)">
											<img :src="item.image" alt="" />
										</div>
									</div>
								</a-col>
							</a-row>
							<div class="status_text" v-if="local.data.length">
								<span class="more" v-if="local.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
								<span v-if="local.status == 'loading'">加载中...</span>
								<span v-if="local.status == 'nomore'">没有更多了</span>
							</div>
						</div>
					</div>
				</div>
			</a-modal>
		</template>

		<!-- 裁剪弹窗 -->
		<a-modal
			title="裁剪"
			width="60%"
			:visible="visibleCropper"
			okText="上传"
			cancelText="取消"
			@ok="handleOkCropper"
			@cancel="handleCancelCropper">
			<cropper
				:Name="cropperName"
				@uploadImgSuccess="handleUploadSuccess"
				@getCropData="getCropData"
				:cropper-data="cropperData"
				ref="child">
			</cropper>
		</a-modal>

		<!-- 分享海报 -->
		<div class="chat_share_poster" v-show="show">
			<div class="ChatSharePoster" @click="$emit('close')">
				<div @click="downloadPage" class="poster_box">
					<div class="poster" id="pagePoster" ref="pagePoster" :style="{ background: posterBgColor }">
						<img width="280" height="320" :src="posterBgImage" crossorigin="undefined" alt="" />
						<div class="text_box">
							<span class="title">{{ form.title ? form.title : "wow" }}</span>
							<span class="content">{{ form.welcome ? form.welcome : "我是wow" }}</span>
						</div>
						<div class="user_box">
							<div class="infos">
								<div class="avatar">
									<img
										ref="mapCanvas"
										id="mapCanvas"
										class="img"
										:src="form.image ? form.image : logo"
										crossorigin="undefined" />
								</div>
								<div class="nickname">
									<div class="text1">{{ form.title ? form.title : "wow" }}</div>
									<div class="invite">邀您与我语音聊天</div>
								</div>
							</div>
							<div class="qrcode">
								<div class="img">
									<div ref="qrCodeUrl" id="qrCodeUrl"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="poster_img">
						<image class="img" :src="posterImg" mode="aspectFit" show-menu-by-longpress></image>
					</div>
				</div>
				<div class="tips">点击图片保存到相册</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { create, all } from "mathjs";
	const math = create(all, {
		number: "BigNumber",
		precision: 20,
	});
	import moment from "moment";
	import { apiList } from "@/api/api";
	import { downloadPicture, sizePicture, Debounce, timestampToTime } from "@/utils/tools";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	import html2canvas from "html2canvas";
	import CProgress from "@/components/c-progress.vue";
	import cropper from "@/components/cropper.vue"; //
	import QRCode from "qrcodejs2"; //

	import { VueCropper } from "vue-cropper"; //

	export default {
		components: {
			CProgress,
			cropper,
			VueCropper,
		},
		data() {
			return {
				labelCol: { span: 2 },
				wrapperCol: { span: 16 },
				other: "",
				form: {
					name: "",
					sex: 2,
					image: "",
					desc: "",
					prompt: "",
					hint: "",
					welcome: "",
					is_publicity: 2,
					id: "",
					title: "",
					topic_id: "",
					titleText: "",
					controls_status: "",
					usages: "",
					time: moment("2000-01-01", "YYYY/MM/DD"), //出生日期
					pet: "", //宠物
					grow: "", //成长经历
					knowledge: "", //专业知识
					interest: "", //兴趣爱好
					domains: [],
					birthday: moment("2000-01-01", "YYYY/MM/DD"),
					// character_id: '1',  //性格
					characterID: 1,
					social_code: "wb",
					social_link: [
						{
							wb: "",
						},
						{
							xhs: "",
						},
						{
							dy: "",
						},
						{
							other: "",
						},
					], //分享链接
				},
				dateFormat: "YYYY/MM/DD",
				moment,
				topic: [],
				rules: {},
				/************************图片上传**********************************/
				fileList: null,
				// 添加图片
				picture: {
					show: false,
					type: 1,
					active: null,
					image: "",
				},
				// 作品图片库
				works: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore", // loadmore nomore loading
				},
				// 本地图片库
				local: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore", // loadmore nomore loading
				},
				// 上传信息
				upload: {
					loading: false,
					action: apiList.common.upload.url,
					headers: {
						token: "",
					},
					accept: "image/png, image/jpeg",
					data: {
						is_user_image: 1,
					},
				},
				type: false, //
				avater: require("@/assets/imgs/touxiang.png"),
				logo: require("@/assets/imgs/touxiang.png"),
				getID: "",

				activeKey: ["1", "2", "4", "7"],
				//网红声线
				soundList: [],
				size: "small",
				placeholder: "您好，我是wow，您喜欢我的声音吗？",
				// 分享海报
				visibleShare: true,
				show: false,
				posterBgImage: this.$store.state.app.share?.image || "", // 海报背景图片
				posterBgColor: this.$store.state.app.share?.msg_title_bg_color || "", // 海报背景颜色
				UID: this.$store.state.user.userInfo.id || 0, // uid
				qrcodeValue: "", // 二维码参数

				textData: {}, // 绘制信息
				posterImg: "", // 海报图片

				display: "none",
				confirmLoading: false,
				qrcodeShare: "", //海报二维码
				ggg: "",

				/****************************记忆库************************************/
				// 添加
				formItemLayout: {
					labelCol: {
						xs: { span: 3 },
						sm: { span: 3 },
					},
					wrapperCol: {
						xs: { span: 3 },
						sm: { span: 18 },
					},
				},
				formItemLayoutWithOutLabel: {
					wrapperCol: {
						xs: { span: 24, offset: 0 },
						sm: { span: 20, offset: 4 },
					},
				},
				dynamicValidateForm: {
					domains: [],
				},
				// 添加记忆库弹窗
				visibleMemory: false,
				formMemory: {
					name: "",
					text: "",
				},
				rulesMemory: {
					name: [{ required: true, message: "请输入记忆库名称", trigger: "change" }],
					text: [{ required: true, message: "请输入记忆库详情", trigger: "change" }],
				},
				// 记忆库
				character: [],
				characterRadio: [],
				checkedText: "",
				/**
				 * MBTI 是一种基于卡尔．荣格的人格心理学理论开发而来的心理测量工具。
				 * 它通过对个体在四个偏好维度上的倾向进行测量，包括内向／外向、感觉／直觉、思考／情感、判断／知觉
				 * ，从而将人格分为16种类型。 MBTI 在职业发展、人际关系和团队协作等领域有广泛应用，帮助人们更好地认识自己和他人。**/
				tagList: [],
				name: "",
				radioData: 1,
				feelingList: [],
				text: "您好，我是wow，您喜欢我的声音嘛",
				role_id: "",
				voice_id: "",
				emotion_intensity: 50, //情感程度 [50,200] 整数
				speed: "", //语速 [-2,6] -2代表0.6倍,-1代表0.8倍,0代表1.0倍（默认）,1代表1.2倍,2代表1.5倍,6代表2.5倍
				volume: "", //音量大小 [0,10]
				emotion_category: "", //情感风格 取值: neutral(中性)、sad(悲伤)、happy(高兴)、angry(生气)、fear(恐惧)、news(新闻)、story(故事)、radio(广播)、poetry(诗歌)、call(客服)、撒娇(sajiao)、
				// 厌恶(disgusted)、震惊(amaze)、平静(peaceful)、兴奋(exciting)、傲娇(aojiao)、解说(jieshuo)
				codec: "", //音频类型 wav,mp3,aac,m4a
				audioSrc: "",
				isPlay: false,
				emotion_list: {},
				/***************************裁剪***************************/
				visibleCropper: false,

				cropperName: "裁剪",
				cropperData: "",
				testData: null,
				color: [
					"colorOne",
					"colorOne",
					"colorOne",
					"colorOne",
					"colorTwo",
					"colorTwo",
					"colorTwo",
					"colorTwo",
					"colorThere",
					"colorThere",
					"colorThere",
					"colorThere",
					"colorFour",
					"colorFour",
					"colorFour",
					"colorFour",
				],
				memory_length: "",
				defaultStyle: "",
				pink: require("@/assets/imgs/pink.png"),
				blue: require("@/assets/imgs/blue.png"),
				green: require("@/assets/imgs/green.png"),
				purple: require("@/assets/imgs/purple.png"),
				backIndex: "",
				age: "2000",
				reseller: false,
				defaultRole: [],
				roleModuleData: {},
				defaultPlay: require("@/assets/imgs/defplay.png"),
				bluePlay: require("@/assets/imgs/play.png"),
				isshiTing: false, //判断是否是试听
			};
		},
		computed: {
			...mapGetters("user", ["token"]),
			...mapGetters("app", ["config"]),
			...mapGetters("app", ["share"]),
		},
		/************************图片上传**********************************/
		watch: {
			"picture.show": {
				deep: true,
				handler: function (newValue, oldValue) {
					if (!newValue) {
						// this.picture.active = null
						// this.picture.image = ""
					}
				},
			},
			"picture.type": {
				deep: true,
				handler: function (newValue, oldValue) {
					this.picture.active = null;
					this.picture.image = "";
				},
			},
			activeKey(key) {
				console.log(key);
			},
			config: {
				deep: true,
				handler: function (newValue, oldValue) {
					this.posterBgColor = newValue.share?.msg_title_bg_color || "";
					this.posterBgImage = newValue.share?.msg_title_bg || "";
				},
			},
			data: {
				deep: true,
				handler: function (newValue, oldValue) {
					if (!newValue?.id) {
						this.$emit("close");
						return;
					}
					if (newValue?.response != oldValue?.response) {
						this.textData = newValue;
						this.isCreate = true;
						this.posterImg = "";
					}
				},
			},
			$route: "getPath",
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
		},
		created() {
			this.upload.headers.token = this.token;
			let vuex = localStorage.getItem("vuex");
			this.nickname = JSON.parse(vuex).user.userInfo.nickname;
			this.gptType(); //获取AI类型
			this.getCharacter(); //获取性格
			this.voiceConfig(); //声线配置
			this.indexRoleModule(); //
			if (this.token != "") {
				this.getDrawingRecord(); //获取绘画记录
				this.getUploadMatter(); //获取素材数据
				this.examine(); //查询接口
			} else {
				this.backIndex = this.pink;
			}
			this.$nextTick(() => {
				this.posterBgImage = this.posterBgImage + "?v=" + new Date().valueOf();
			});
		},
		mounted() {
			let id = this.$store.state.user.userInfo.id;
			setTimeout(() => {
				let name = "";
				this.form?.title == undefined ? (name = "wow") : (name = this.form.title);
				const spm = id + ".1.0.4.3";
				let sign = window.location.search.replace(/\?/g, "");
				this.qrcodeValue = name + "邀您语音对话：" + this.$BASE_API + `?${sign}#/?share=${spm}`;
			}, 2000);
			this.creatQrCode();
			window.addEventListener("scroll", this.handleScroll);

			// let spm1 = id + ".1.0.1.2";
			// this.qrcodeShare = 'https://wanghongxiu.com/pc/#/role' ;
			// this.creatQrCode();
		},

		methods: {
			// 进入页面高度
			getPath() {
				document.querySelector("#CharacterGrid").style.top = 270 + "px";
			},
			// 监听页面的 scroll 事件
			handleScroll(e) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 100) {
					document.querySelector("#CharacterGrid").style.top = 50 + "px";
				}
				if (scrollTop < 250) {
					document.querySelector("#CharacterGrid").style.top = 270 + "px";
				}
			},
			// 获取AI类型
			gptType() {
				this.$http("drawing.type").then((res) => {
					if (res.code === 1) {
						this.topic = res.data;
						this.form.topic_id;
						this.form.topic_id = res.data[0].id;
					}
				});
			},

			// 获取性格
			getCharacter() {
				this.$http("user.getCharacter").then((res) => {
					if (res.code === 1) {
						this.character = res.data;
						this.checkedText = res.data[0].volume;
						this.tagList = res.data[0].type_name;
						this.name = res.data[0].name;
						res.data.forEach((item) => {
							this.characterRadio.push(item.abbreviation);
						});
					}
				});
			},
			// 获取声线配置voiceConfig
			voiceConfig() {
				this.$http("user.voiceConfig").then((res) => {
					if (res.code == 1) {
						this.soundList = res.data.voive_type;
						let data = res.data.voive_type[0];
						this.feelingList.push(data);
						this.changeVoiceId = this.feelingList[0].voice_id;
						let arr = Object.keys(this.feelingList[0].emotion_category);
						this.emotion_category = arr[0];
						this.defaultStyle = arr[0]; //风格默认
					}
				});
			},

			// 请登录
			saveSound(type) {
				switch (type) {
					case "sound":
						if (this.isshiTing || this.audioSrc == "") {
							this.synthesis();
						} else {
							this.play();
						}
						break;
				}
				if (!this.token) {
					this.$message.error("请登录");
				}
			},
			// 请登录
			isToken(type) {
				switch (type) {
					case "picture":
						if (this.token) {
							this.picture.show = true;
						} else {
							this.$message.error("请登录");
						}
						break;
					case "title":
						if (!this.token) {
							this.$message.error("请登录");
						}
						break;
					case "welcome":
						if (!this.token) {
							this.$message.error("请登录");
						}
						break;
					case "text":
						this.isshiTing = true;
						break;
					default:
						if (!this.token) {
							this.$message.error("请登录");
						}
						break;
				}
			},
			// 获取模板
			gptTypePrompt() {
				let data = {
					name: this.form.titleText,
					sex: this.form.sex,
				};
				this.$http("drawing.prompt", data).then((res) => {
					if (res.code === 1) {
						this.form = {
							name: "",
							image: "",
							is_publicity: 2,
							id: "",
							title: this.form.title,
							titleText: "",
							controls_status: "",
							usages: "",
						};
						this.topic.forEach((item) => {
							if (res.data.name == item.title) {
								this.form.topic_id = item.id;
							}
						});
						this.form.is_publicity = 2;
						this.form.image = this.picture.image + "?v=" + new Date().valueOf();
					}
				});
			},
			// 获取角色信息
			examine() {
				this.$http("drawing.examine").then((res) => {
					this.type = false;
					let callback = {};
					if (res.code === 1) {
						this.reseller = res.data?.reseller;
						res.data.data.length > 0 ? (callback = res.data.data.pop()) : (callback = res.data.data[0]);
						// this.$store.commit("user/setRoleData",callback)
						if (callback != undefined) {
							this.audioSrc = res.data.voice_data?.voice_url;
							// 处理一下
							this.form = callback;
							this.getID = this.form.id || "";
							this.picture.image = this.form.image;
							this.form.birthday = moment(callback.birthday, this.dateFormat);
							this.age = moment(this.form.birthday).format("YYYY");
							if (this.form.social_link.length == 0) {
								this.form.social_link = [
									{
										wb: "",
									},
									{
										xhs: "",
									},
									{
										dy: "",
									},
									{
										other: "",
									},
								];
							}
							this.form.social_code ? (this.form.social_code = callback.social_code) : (this.form.social_code = "wb");
							this.token ? this.changeBack(this.form.character_id) : (this.backIndex = this.pink);
							// 有记忆库处理
							if (callback.memory_bank) {
								this.form.pet = callback?.memory_bank?.pet;
								this.form.grow = callback?.memory_bank?.grow;
								this.form.knowledge = callback?.memory_bank?.knowledge;
								this.form.interest = callback?.memory_bank?.interest;
								this.memory_length = Object.keys(this.form.memory_bank).length;
								let data = Object.entries(callback.memory_bank);
								data.forEach((item) => {
									if (
										item.indexOf("pet") &&
										item.indexOf("grow") &&
										item.indexOf("knowledge") &&
										item.indexOf("interest")
									) {
										this.dynamicValidateForm.domains.push({
											value: item[1],
											label: item[0],
											key: Date.now(),
										});
									}
								});
							} else {
								this.form.memory_bank = {};
							}
							this.form.characterID = this.form.character_id || "1";
							this.radioData = this.form.character_id || 1;
							this.character.forEach((item) => {
								if (item.id == this.form.characterID) {
									this.checkedText = item.volume;
									this.tagList = item.type_name;
									this.name = item.name;
								}
							});
							// controls_status 1通过【可以编辑】    2 审核中【不可编辑】   3 拒绝【可以编辑】
							if (this.form.controls_status == 2) {
								this.type = true;
							}
							this.role_id = this.form.id;
							this.$emit("IdChange", this.form.controls_status);
						} else {
							this.form = {
								name: "",
								sex: 2,
								image: "",
								desc: "",
								prompt: "",
								hint: "",
								welcome: "",
								is_publicity: 2,
								id: "",
								title: "",
								topic_id: "",
								titleText: "",
								controls_status: "",
								usages: "",
								time: moment("2000-01-01", "YYYY/MM/DD"), //出生日期
								pet: "", //宠物
								grow: "", //成长经历
								knowledge: "", //专业知识
								interest: "", //兴趣爱好
								domains: [],
								birthday: moment("2000-01-01", "YYYY/MM/DD"),
								// character_id: '1',  //性格
								characterID: 1,
								social_code: "wb",
								social_link: [
									{
										wb: "",
									},
									{
										xhs: "",
									},
									{
										dy: "",
									},
									{
										other: "",
									},
								], //分享链接
							};
						}
					}
				});
			},

			/*****************************网红模板****************************************/
			// 获取网红模板
			indexRoleModule() {
				this.$http("role.indexRoleModule").then((res) => {
					if (res.code == 1) {
						this.defaultRole = res.data;
						if (!this.token) {
							let data = this.defaultRole[0];

							this.token ? this.changeBack(data.character_id) : (this.backIndex = this.pink); //背景图
							this.age = moment(data).format("YYYY");
							this.form = { ...data };
							this.form.characterID = data.character_id || "1";
							this.radioData = this.form.character_id || 1;
							this.picture.image = this.form.image;
							this.form.pet = data?.memory_bank?.pet;
							this.form.grow = data?.memory_bank?.grow;
							this.form.knowledge = data?.memory_bank?.knowledge;
							this.form.interest = data?.memory_bank?.interest;
							this.memory_length = Object.keys(this.form.memory_bank).length;
							this.form.social_link = [
								{
									wb: "",
								},
								{
									xhs: "",
								},
								{
									dy: "",
								},
								{
									other: "",
								},
							];
							this.form.social_code = "wb";
						}
						if (this.getID == "") {
							let data = this.defaultRole[0];
							this.token ? this.changeBack(data.character_id) : (this.backIndex = this.pink); //背景图
							this.age = moment(data).format("YYYY");
							this.form = { ...data };
							this.form.characterID = data.character_id || "1";
							this.radioData = this.form.character_id || 1;
							this.picture.image = this.form.image;
							this.form.pet = data?.memory_bank?.pet;
							this.form.grow = data?.memory_bank?.grow;
							this.form.knowledge = data?.memory_bank?.knowledge;
							this.form.interest = data?.memory_bank?.interest;
							this.memory_length = Object.keys(this.form.memory_bank).length;
							this.form.social_link = [
								{
									wb: "",
								},
								{
									xhs: "",
								},
								{
									dy: "",
								},
								{
									other: "",
								},
							];
							this.form.social_code = "wb";
						}
					}
				});
			},
			// 切换模板
			changeRole(item) {
				this.form = {};
				this.form = JSON.parse(JSON.stringify(item));
				this.changeBack(item.character_id);
				this.radioData = this.form.character_id || 1;
				this.picture.image = this.form.image;
				this.memory_length = Object.keys(this.form.memory_bank).length;
				this.changeData(item);
				this.form.memory_bank = {};
				this.$emit("roleModule", item);
				this.form.social_link = [
					{
						wb: "",
					},
					{
						xhs: "",
					},
					{
						dy: "",
					},
					{
						other: "",
					},
				];
				this.form.social_code = "wb";
			},
			// 数据处理
			changeData(some) {
				if (some.memory_bank) {
					this.age = moment(some.birthday).format("YYYY");
					this.form.pet = some?.memory_bank?.pet;
					this.form.grow = some?.memory_bank?.grow;
					this.form.knowledge = some?.memory_bank?.knowledge;
					this.form.interest = some?.memory_bank?.interest;
					let data = Object.entries(some.memory_bank);
					data.forEach((item) => {
						if (item.indexOf("pet") && item.indexOf("grow") && item.indexOf("knowledge") && item.indexOf("interest")) {
							this.dynamicValidateForm.domains.push({
								value: item[1],
								label: item[0],
								key: Date.now(),
							});
						}
					});
					this.form.characterID = this.form.character_id || "1";
					this.radioData = this.form.character_id || 1;
					this.character.forEach((item) => {
						if (item.id == this.form.characterID) {
							this.checkedText = item.volume;
							this.tagList = item.type_name;
							this.name = item.name;
						}
					});
				}
			},

			/****************************记忆库************************************/
			// 添加记忆库
			addDomain() {
				if (this.dynamicValidateForm.domains.length < 6) {
					if (this.token) {
						this.visibleMemory = true;
					} else {
						this.$message.error("请登录");
					}
				}
			},
			// 移除记忆库
			removeDomain(item) {
				let index = this.dynamicValidateForm.domains.indexOf(item);
				if (index !== -1) {
					this.dynamicValidateForm.domains.splice(index, 1);
				}
			},
			// 记忆库确认
			handleCancelMemory() {
				this.$refs.ruleFormMemory.resetFields();
				this.visibleMemory = false;
			},
			// 记忆库取消
			handleOkMemory() {
				this.dynamicValidateForm.domains.push({
					value: this.formMemory.name,
					label: this.formMemory.text,
					key: Date.now(),
				});
				this.visibleMemory = false;
			},

			// 时间选择
			changeTime(value) {
				this.form.time = moment(value).format("YYYY-MM-DD");
				this.age = moment(value).format("YYYY");
			},
			// 分类下拉
			handlerSelect(val) {
				this.form.topic_id = val;
				this.topic.forEach((item) => {
					if (val == item.id) {
						this.form.titleText = item.title;
					}
				});
			},
			// 进入当前分类
			classifyItem() {
				// 审核成功，可进入聊天
				if (this.form.controls_status === 1) {
					this.$router.push("/role/chat?id=" + this.form.id);
				}
			},
			// 切换性格
			changeRadio(e) {
				this.character.forEach((item) => {
					if (item.id == e.target.value) {
						this.checkedText = item.volume;
						this.tagList = item.type_name;
						this.name = item.name;
						this.form.characterID = item.id;
					}
				});
				this.changeBack(e.target.value);
			},

			// 切换性格背景图
			changeBack(value) {
				switch (true) {
					case value <= 4:
						this.backIndex = this.pink;
						break;
					case value >= 5 && value <= 8:
						this.backIndex = this.green;
						break;
					case value > 8 && value <= 12:
						this.backIndex = this.blue;
						break;
					case value > 12 && value <= 16:
						this.backIndex = this.purple;
						break;
				}
			},

			// 创建网红
			addUser() {
				this.form.time = moment(this.form.birthday).format("YYYY-MM-DD");
				this.$http("drawing.text", this.form).then((res) => {
					if (res.code === 1) {
						this.type = true;
						if (this.form.is_publicity == 2) {
							this.form.controls_status = 1; //提交了就是待审核
							this.$emit("IdChange", this.form.controls_status);
						}
					}
				});
			},
			// 提交
			submitForm() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.form.domains = this.dynamicValidateForm.domains;
						this.form.time = this.form.birthday;
						this.getID ? (this.form.id = this.getID) : (this.form.id = "");
						this.addUser();
					} else {
						return false;
					}
				});
				this.form.controls_status = 2; //提交了就是待审核
				this.$emit("IdChange", this.form.controls_status);
				this.$emit("roleModule", this.form);
			},
			changeRoleModule(value, type) {
				this.roleModuleData = this.form;
				switch (type) {
					case "title":
						this.roleModuleData.title = value;
						break;
					case "welcome":
						this.roleModuleData.welcome = value;
						break;
					case "image":
						this.roleModuleData.image = value;
						break;
				}
				this.$emit("roleModule", this.roleModuleData);
			},

			/*********************************************声线设置*******************************************/
			// 情感
			onPercentChange(per) {
				this.emotion_intensity = per;
				this.isshiTing = true;
			},
			// 语速
			onPercentChangeSpeed(per) {
				this.speed = per;
				this.isshiTing = true;
			},
			// 音量
			onPercentChangeVolume(per) {
				this.volume = per;
				this.isshiTing = true;
			},
			// 风格
			selectStyle(val, key) {
				this.emotion_category = key;
				this.defaultStyle = "";
				this.isshiTing = true;
			},
			// 播放
			playVoice(item) {
				let obj = {
					codec: "mp3",
					role_id: this.role_id,
					emotion_category: Object.keys(item.emotion_category)[0],
					emotion_intensity: 100,
					speed: 0,
					text: "我是" + item.name,
					voice_id: item.voice_id,
					volume: 2,
				};
				this.getAudio(obj);
			},
			changeSound(item, index) {
				this.feelingList = [];
				this.feelingList.push(item);
				this.voice_id = this.feelingList[0].voice_id;
				this.soundList.forEach((e) => {
					if (item.voice_id == e.voice_id) {
						this.changeVoiceId = e.voice_id;
					}
				});
				this.emotion_category = Object.keys(item.emotion_category)[0];
				this.defaultStyle = Object.keys(item.emotion_category)[0]; //风格默认选中第一个
				this.isshiTing = true;
			},
			// 合成
			synthesis() {
				let data = {
					text: this.text,
					role_id: this.role_id,
					voice_id: this.voice_id,
					emotion_intensity: parseInt((this.emotion_intensity + 50) * 1.5), //情感程度 [50,200] 整数
					speed: 1, //语速 [-2,6] -2代表0.6倍,-1代表0.8倍,0代表1.0倍（默认）,1代表1.2倍,2代表1.5倍,6代表2.5倍
					volume: parseInt(this.volume * 0.1), //音量大小 [0,10]
					emotion_category: this.emotion_category, //情感风格 取值: neutral(中性)、sad(悲伤)、happy(高兴)、angry(生气)、fear(恐惧)、news(新闻)、story(故事)、radio(广播)、poetry(诗歌)、call(客服)、撒娇(sajiao)、
					// 厌恶(disgusted)、震惊(amaze)、平静(peaceful)、兴奋(exciting)、傲娇(aojiao)、解说(jieshuo)
					codec: "mp3", //音频类型 wav,mp3,aac,m4a
				};
				data.emotion_intensity > 200 ? (data.emotion_intensity = 200) : parseInt((this.emotion_intensity + 50) * 1.5);
				switch (true) {
					case this.speed < 17:
						data.speed = -2;
						break;
					case this.speed >= 17 && this.speed < 33:
						data.speed = -1;
						break;
					case this.speed >= 33 && this.speed < 50:
						data.speed = 0;
						break;
					case this.speed >= 50 && this.speed < 66:
						data.speed = 1;
						break;
					case this.speed >= 66 && this.speed < 83:
						data.speed = 2;
						break;
					case this.speed >= 83 && this.speed < 100:
						data.speed = 6;
						break;
					default:
						data.speed = 1;
						return;
				}
				this.getAudio(data);
			},
			// 播放
			play() {
				console.log("登陆自动播放");
				this.$refs.audio[0].play();
			},
			getAudio(data) {
				this.$http("user.getAudio", data).then((res) => {
					if (res.code == 1) {
						this.isPlay = true;
						this.audioSrc = res.data.url;
						this.isshiting = false;
					}
				});
			},

			/***************************分享*************************/
			// 分享链接
			link(type) {
				const input = document.createElement("input");
				input.setAttribute("readonly", "readonly");
				input.setAttribute("value", this.qrcodeValue);
				document.body.appendChild(input);
				input.select();
				if (document.execCommand("copy")) {
					document.execCommand("copy");
					switch (type) {
						case "weixin":
							this.$message.success("已复制分享口令和链接，请前往微信粘贴");
							break;
						case "qq":
							this.$message.success("已复制分享口令和链接，请前往QQ粘贴");
							break;
						case "other":
							this.$message.success("已复制分享口令和链接，请前往分享");
							break;
						default:
							break;
					}
				}
				document.body.removeChild(input);
			},
			// 分享海报
			canvasCode() {
				this.show = true;
				this.form == undefined ? this.$message.error("请先创建网红哦！") : this.createPosterImage();
				// this.form.image = this.form.image + "?v=" + new Date().valueOf();
				// console.log("添加了时间戳", this.form.image);
			},
			// 获取生成的base64图片路径
			receiveRenderData(val) {
				const url = val.replace(/[\r\n]/g, ""); // 去除base64位中的空格
				this.posterImg = url;
			},
			// 生成二维码
			creatQrCode() {
				if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = "";
				let id = this.$store.state.user.userInfo.id;
				const spm = id + ".1.0.2.2";
				let sign = window.location.search.replace(/\?/g, "");
				let url = "https://m.wanghongxiu.com";
				this.qrcodeShare = url + `?${sign}#/pages/role/init?scene=${spm}`;
				let data = new QRCode("qrCodeUrl", {
					text: this.qrcodeShare, // 需要转换为二维码的内容
					width: 60,
					height: 60,
				});
				console.log("生成二维码", data);
			},
			// 生成海报图片
			createPosterImage() {
				const time = setTimeout(() => {
					const dom = document.getElementById("pagePoster"); // 需要生成图片内容的 dom 节点
					html2canvas(dom, {
						width: dom.clientWidth, //dom 原始宽度
						height: dom.clientHeight,
						backgroundColor: "transparent",
						scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
						scrollX: 0,
						useCORS: true, //支持跨域
						scale: 1, // 设置生成图片的像素比例，默认是1，如果生成的图片模糊的话可以开启该配置项
					})
						.then((canvas) => {
							this.receiveRenderData(canvas.toDataURL("image/png"));
							this.ggg = canvas.toDataURL("image/png");
						})
						.catch((err) => {
							this.$message.error("【生成图片失败，请重试】!");
						});
					clearTimeout(time);
				}, 500);
			},
			// 下载海报
			downloadPage() {
				const file = document.createElement("a");
				file.href = this.ggg;
				file.download = this.form.title + "-wow";
				document.body.appendChild(file);
				file.click();
				document.body.removeChild(file);
				this.show = false;
			},

			/************************************************关于图片上传的所有方法*********************************************************/

			// 加载更多图片 点击
			loadMorePicture() {
				if (this.picture.type == 1) {
					// console.log("作品图片库");
					this.works.page++;
					this.getDrawingRecord();
				} else {
					// console.log("本地图片库");
					this.local.page++;
					this.getUploadMatter();
				}
			},
			getCropData(value) {
				this.testData = value;
				this.uploadPictureBefore(this.testData);
			},
			// 上传图片之前
			uploadPictureBefore(file) {
				// console.log("上传图片之前11", file);
				const isPNG = file.type === "image/jpeg" || "image/png";
				if (!isPNG) {
					this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
					return isPNG;
				}
				const isLt2M = file.size / 1024 / 1024 < 20;
				if (!isLt2M) {
					this.$message.error("上传图片!");
					return isLt2M;
				}
				const isSize = this.isSize(file);
				return isPNG && isLt2M && isSize;
			},
			// 文件类型转base64
			fileToBase64(file, callback) {
				const reader = new FileReader();
				reader.onload = function (evt) {
					if (typeof callback === "function") {
						callback(evt.target.result);
					}
				};
				reader.readAsDataURL(file);
			},
			//检测尺寸
			isSize(file) {
				const base64 = this.fileToBase64(file, (base64) => {
					this.cropperData = base64;
				});
				return new Promise((resolve, reject) => {
					if (file.type.startsWith("image/")) {
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = (e) => {
							const img = new Image();
							img.src = reader.result;
							img.onload = () => {
								if (img.naturalWidth !== 400 || img.naturalHeight !== 400) {
									this.$message.error("上传图片尺寸必须是400*400!");
									this.visibleCropper = true;
									this.cropperData = e.target.result;
									this.$refs.child.sendItem((this.cropperData = e.target.result));
									reject();
								} else {
									resolve();
								}
							};
						};
					}
				});
			},
			// 上传图片之后
			uploadPictureChange(info) {
				console.log("上传图片之后", info);
				if (info.file.status === "uploading") {
					this.upload.loading = true;
					return;
				}
				if (info.file.response.code != 1) {
					this.upload.loading = false;
					this.$message.error(info.file.response.msg);
					return;
				}
				if (info.file.status === "done") {
					this.upload.loading = false;
					const time = new Date().getTime();
					this.local.data.unshift({
						id: "local_" + time,
						image: info.file.response.data.fullurl,
					});
				}
				this.form.image = info.file.response.data.fullurl;
			},
			//上传
			handleOkCropper() {
				let formData = new FormData();
				formData.append("is_user_image", 1);
				formData.append("file", this.testData);
				this.$http("common.upload", formData).then((res) => {
					if (res.code == 1) {
						// 弹窗消失
						this.visibleCropper = !this.visibleCropper;
						// 本地上传加一
						this.upload.loading = false;
						const time = new Date().getTime();
						this.local.data.unshift({
							id: "local_" + time,
							image: res.data.fullurl,
						});
						// 头像回显
						this.form.image = res.data.fullurl;
						// 清除截图框的历史数据
						this.cropperData = "";
						this.$refs.child.sendItem((this.cropperData = ""));
					}
				});
			},
			handleCancelCropper() {
				this.visibleCropper = !this.visibleCropper;
			},
			handleUploadSuccess() {},
			// 修改当前选中添加的图片
			changeAddPicture(id, image) {
				this.form.avater = image;
				this.picture.active = id;
				this.picture.image = image;
				this.form.image = image;
			},
			// 添加图片确认按钮
			addPictureOkHandle() {
				this.picture.show = false;
				this.changeRoleModule(this.form.image, "image");
			},
			// 滚动条监听
			addPictureScroll: Debounce(
				function (e) {
					let scrollTop = e.target.scrollTop;
					let clientHeight = e.target.clientHeight;
					let scrollHeight = e.target.scrollHeight;
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						if (this.picture.type == 1) {
							// console.log("作品图片库");
							this.works.page++;
							this.getDrawingRecord();
						} else {
							// console.log("本地图片库");
							this.local.page++;
							this.getUploadMatter();
						}
					}
				},
				20,
				false,
			),
			// 获取素材数据
			getUploadMatter() {
				this.local.status = "loading";
				this.$http("drawing.matter", { page: this.local.page, pagesizze: this.local.pagesize }).then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, fullimage }) => ({ id: "local_" + id, image: fullimage }));
							this.local.data = this.local.data.concat(arr);
							this.local.status = "loadmore";
						} else {
							this.local.page--;
							this.local.status = "nomore";
						}
					}
				});
			},
			// 获取绘画记录
			getDrawingRecord() {
				this.works.status = "loading";
				this.$http("drawing.record", { page: this.works.page, pagesize: this.works.pagesize }).then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.works.data = this.works.data.concat(res.data);
							this.works.status = "loadmore";
						} else {
							this.works.page--;
							this.works.status = "nomore";
						}
					}
				});
			},
			// 删除上传图片
			deleteUploadPicture() {
				this.$confirm({
					title: "确定要删除图片吗？",
					content: "",
					okText: "确定",
					okType: "danger",
					cancelText: "取消",
					onOk: () => {
						this.picture.image = "";
						this.picture.active = null;
					},
					onCancel: () => {},
				});
			},
		},
		destroyed() {
			window.addEventListener("scroll", this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	.upload {
		width: 90px;
		height: 90px;
		border: 1px dashed #4c5b74;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;

		.image {
			width: 100%;
			height: 100%;
			padding: 4px;
			position: relative;

			&:hover {
				.delete {
					display: flex;

					// pointer-events: auto;
				}
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				// pointer-events: auto;
			}

			.delete {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba($color: #000000, $alpha: 0.5);
				display: flex;
				align-items: center;
				justify-content: center;
				display: none;
				// pointer-events: auto;

				i {
					color: #fff;

					&:hover {
						color: #8895a4;
					}
				}
			}
		}

		.add {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.ant-modal-body {
		padding: 0 24px;
		height: 70vh;
		box-sizing: border-box;

		.picture_box {
			height: 100%;
			display: flex;
			flex-direction: column;

			.box_body {
				flex: 1;
				border: 1px dashed rgba(0, 0, 0, 0.45);
				border-radius: 10px;
				margin: 20px 0 10px 0;
				background: #f9f9fb;
				overflow: hidden;

				.image_list {
					height: 100%;
					overflow: hidden;
					padding: 12px;

					&:hover {
						overflow-y: scroll;
						overflow-x: hidden;
						padding-right: 0;
					}

					&::-webkit-scrollbar {
						width: 12px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 12px;
						border: 4px solid rgba(0, 0, 0, 0);
						box-shadow: 4px 0 0 rgba(0, 0, 0, 0.2) inset;
					}

					&::-webkit-scrollbar-thumb:hover {
						box-shadow: 4px 0 0 #4a4a4a inset;
					}

					// 滚动条两端按钮
					&::-webkit-scrollbar-button {
						// height: 10px;
					}

					.item {
						position: relative;
						width: 100%;
						padding-top: 100%;
						overflow: hidden;

						.image {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							padding: 10px;
							cursor: pointer;
							border-radius: 10px;

							&.active {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);

								img {
									border-color: #424ef2;
								}
							}

							&:hover {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);

								img {
									border-color: #424ef2;
								}
							}

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 10px;
								border: 1.5px solid transparent;
							}
						}

						.upload {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							padding: 10px;
							cursor: pointer;

							.ant-upload-picture-card-wrapper {
								height: 100%;
							}

							::v-deep .ant-upload.ant-upload-select-picture-card {
								width: 100% !important;
								height: 100% !important;
								border-radius: 10px;

								.plus_upload {
								}

								.text {
									font-size: 12px;
									color: #8895a4;
								}
							}
						}
					}

					.status_text {
						text-align: center;
						padding-top: 10px;

						.more {
							cursor: pointer;

							&:hover {
								color: #424ef2;
							}
						}
					}
				}

				.image_select {
					.ant-upload-select-picture-card i {
						font-size: 32px;
						color: #999;
					}
				}
			}
		}
	}

	.ant-modal-footer {
		border: none;
	}

	.disable {
		pointer-events: none;
	}

	.character-slide-card-v3 {
		flex: 1 1 0%;
		width: 208px;
		height: 240px;
		background: rgb(243, 240, 240);
		display: flex;
		border-radius: 12px;
		justify-content: space-between;
		align-items: center;
		padding: 12px;
		flex-direction: column;
		--darkreader-inline-bgimage: initial;
		--darkreader-inline-bgcolor: #2b2c2d;
		// #2b2c2d rgb(43, 44, 45)
	}

	.character-slide-card-v3:hover {
		background-color: #dddbdb;
	}

	/deep/ .ant-radio {
		position: relative !important;
		top: -1px !important;
		left: 7px !important;
	}

	.ant-input {
		font-weight: normal !important;
	}

	.ant-select {
		font-weight: normal !important;
	}

	::v-deep .ant-input-group-addon {
		border: 1px solid #d9d9d9;
		border-left: 0;
	}

	.textarea {
		position: relative !important;
	}

	.share {
		display: flex;
		text-align: left;
		// margin: auto;
		margin-top: 18px;

		div {
			margin-left: 10px;
			cursor: pointer;
		}

		.img1 {
			width: 39px;
			height: 39px;
			background-image: url("../../assets/imgs/weixin6.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img1:hover {
			width: 39px;
			height: 39px;
			background-image: url("../../assets/imgs/weixin10.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img2 {
			width: 40px;
			height: 40px;
			background-image: url("../../assets/imgs/QQ4.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img2:hover {
			width: 40px;
			height: 40px;
			background-image: url("../../assets/imgs/QQ3.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img3 {
			width: 40px;
			height: 40px;
			background-image: url("../../assets/imgs/erweima2.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img3:hover {
			width: 39px;
			height: 39px;
			background-image: url("../../assets/imgs/erweima9.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img4 {
			width: 40px;
			height: 40px;
			background-image: url("../../assets/imgs/lianjie.png");
			background-repeat: no-repeat;
			background-size: cover;
		}

		.img4:hover {
			width: 40px;
			height: 40px;
			background-image: url("../../assets/imgs/lianjie9.png");
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.carousel {
		width: 200px;
		height: 200px;
		background: #424ef2;
	}

	::v-deep .ant-carousel .slick-slide {
		height: 160px !important;
		overflow: hidden;
	}

	.sound {
		display: grid;
		background: #fff;
		border: 1px solid #eae8e8;
		margin-right: 20px;
		margin-bottom: 20px;
		position: relative;
		border-radius: 7px;
		.playImg {
			width: 25px;
			height: 25px;
			background-image: url("../../assets/imgs/defplay.png");
			background-repeat: no-repeat;
			background-size: cover;
			&:hover {
				width: 25px;
				height: 25px;
				background-image: url("../../assets/imgs/play.png");
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}

	.sound:hover {
		background: rgba(102, 133, 253, 0.3);
		box-shadow: 0px 0px 30px rgba(0, 66, 220, 0.3);
		transform: translateY(-5%);
	}

	.style {
		display: flex;
		flex-wrap: wrap;
		.ant-btn-round {
			margin-left: 10px;
		}
	}

	// 分享海报
	.ChatSharePoster {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		background: rgba(#000, 0.7);
		padding: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.poster_box {
			height: 90%;
			overflow: hidden;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			pointer-events: auto;
			// position: relative;

			.poster {
				width: 300px;
				height: 520px;
				position: relative;
				padding: 10px;
				background: #6a62d1;
				border-radius: 10px;

				position: fixed;
				top: 20%;
				left: 50%;
				// right: 0;
				// bottom: 0;

				.bgimg {
					width: 100%;
					height: auto;
				}

				.text_box {
					margin-top: 10px;
					background: #fff;
					text-indent: 10px;
					border-radius: 5px;
					padding: 7px;

					.title {
						// display: inline-block;
					}

					.content {
						margin-top: 5px;
						display: inline-block;
						// font-size: 28px;
						// white-space: pre-line;
						// word-break: break-all;
						// word-wrap: break-word;
						// padding-bottom: 5px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
					}
				}

				.user_box {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 20px;

					.infos {
						flex: 1;
						margin-right: 20px;
						display: flex;
						align-items: center;

						.avatar {
							width: 70px;
							height: 70px;
							border-radius: 50%;
							overflow: hidden;
							background: #fff;
							padding: 4px;

							.img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
							}
						}

						.nickname {
							flex: 1;
							// font-size: 28px;
							margin-left: 20px;
							color: #fff;

							.text1 {
								font-weight: 700;
								margin-bottom: 8px;
							}
						}
					}

					.nickname {
						.invite {
							font-size: 10px;
						}
					}

					.qrcode {
						width: 70px;
						height: 70px;
						background: #fff;
						// border-radius: 8px;
						padding: 5px;

						.img {
							width: 60px;
							height: 60px;

							//     div {
							//         width: 60px;
							//         height: 60px;
							//     }
						}
					}
				}
			}

			.poster_img {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				display: flex;
				align-items: center;
				justify-content: center;

				// display: none;

				.img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.tips {
			// position: relative;
			position: fixed;
			bottom: 20%;
			left: 54%;

			// width: 100%;
			// text-align: center;
			color: #fff;
			font-size: 16px;
			// font-weight: bold;
		}
	}

	::v-deep .ant-select-selection--single .ant-select-selection__rendered {
		width: 160px !important;
	}

	::v-deep .ant-select-selection--single {
		width: 160px !important;
	}

	::v-deep .ant-select-dropdown {
		width: 160px !important;
	}
	::v-deep .ant-select {
		width: 160px !important;
	}
	::v-deep .ant-select-selection--single .anticon {
		right: 0px;
	}
	::v-deep .ant-select-selection--single {
	}
	::v-deep .ant-select-dropdown-menu-item {
		width: 160px !important;
	}

	.time {
		display: inline-block;
		font-size: 12px;
		color: #999;
		margin-left: 5px;
	}

	::v-deep .ant-input {
		font-weight: normal !important;
	}

	.character {
		width: 940px;
		height: 120px;
		color: #fff;
		text-align: center;
		// purple
		background-image: url("../../assets/imgs/pink.jpg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 10px 10px 10px 1px;
		margin-top: -8px;
		border-radius: 10px;
		.name {
			font-size: 24px;
			color: #dab2f9;
			font-weight: bolder;
			text-align: center;
			margin-left: 2px;
		}

		div {
			font-size: 12px;
			line-height: 20px;
			color: #2db7f5;
			text-align: left;
			padding: 0 20px;
			margin-left: 100px;
		}
	}

	::v-deep .ant-radio-wrapper {
		margin-right: 0 !important;
	}

	// ::v-deep .anticon {
	// 	position: absolute !important;
	// }
	.avater-icon {
		// position: absolute;
		// left: 8%;
		// top: 16px;
		margin-right: 5px;
		pointer-events: auto;
		color: #444;
	}

	// 性格选中.ant-radio-group-solid
	::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		background: #72c9a6 !important;
		border: 1px solid #72c9a6 !important;
	}
	.colorOne {
		// background: #ffb2bf;
		background: #e6d7de;
	}
	.colorTwo {
		// background: #b3ffca;
		background: #d6ebe1;
	}
	.colorThere {
		// background: #b3e7ff;
		background: #d0e8ea;
	}
	.colorFour {
		background: #d4b3ff;
	}
	.type {
		border: 1px solid rgba(1, 82, 217, 0.3);
	}

	::v-deep.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		background: #72c9a6 !important;
	}
	::v-deep.ant-radio-button-wrapper {
		color: #fff !important;
	}
	::v-deep.ant-radio-button-wrapper:hover {
		color: #fff !important;
	}

	.atnfoucs {
		border-color: #40a9ff;
	}
	// // 风格选中状态
	// ::v-deep .ant-btn:hover,
	// .ant-btn:focus {
	// 	border-color: #fff !important;
	// 	// border: 1px solid transparent;
	// 	// box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	// }

	::v-deep .ant-calendar-picker {
		width: 280px !important;
	}
	.default_role {
		display: flex;
		margin-bottom: 10px;
		margin-left: 100px;
		// justify-content: center;
		img {
			margin-left: 20px;
			&:hover {
				border: 4px solid #40a9ff;
			}
		}
	}
	.roleImg {
		border: 4px solid #40a9ff;
	}
	::v-deep .ant-col-9 {
		width: 75.5% !important;
	}
	::v-deep .ant-col-15 {
		width: 75.5% !important;
	}
</style>
